import React, { useContext } from "react";
import { useHistory } from "react-router-dom";
import Card from "@mui/material/Card";
import CardActionArea from "@mui/material/CardActionArea";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Button from "@mui/material/Button";
import "./style.scss";
import { IPerson } from "./icard";
import ArrowForwardIosOutlinedIcon from "@mui/icons-material/ArrowForwardIosOutlined";
import { Avatar, Box, CardHeader, IconButton, useTheme } from "@mui/material";
import ApartmentIcon from "@mui/icons-material/Apartment";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { styles } from "../../theme/Theme";
import { UserContext } from "../../context/user-context";
import { DCompany } from "../../model";

const CompanyCard = (props: any) => {
  const { context, contextUpdate, forceUpdate } = useContext(UserContext);

  const history = useHistory();
  const routeTo = (route: string) => {
    history.push(route);
  };

  const company = props.company as DCompany;

  //const theme = useTheme();
  //const classes = styles(theme);

  return (
    <Card className="company-card">
      <Box>
        {company.logo ? (
          <img src={company.logo} width="120px" />
        ) : (
          <ApartmentIcon color="secondary" style={{ fontSize: 64 }} />
        )}
      </Box>
      <Box>
        <h2 className="center info-dark">{company.name}</h2>
      </Box>

      <Box flexGrow={1}>
        <h3 className="center">{props.description}</h3>
        {props.children}
      </Box>

      <Box alignItems="flex-end">
        <CardActions>
          <div className="action-button">
            {props.route ? (
              <Button
                variant="contained"
                size="small"
                color="secondary"
                startIcon={<ArrowForwardIosOutlinedIcon />}
                onClick={() => {
                  routeTo(props.route as string);
                }}
              >
                {props.routeText}
              </Button>
            ) : (
              ""
            )}

            {props.onClick ? (
              <Button
                variant="contained"
                size="small"
                color="secondary"
                onClick={() => {
                  props.onClick();
                }}
              >
                {props.onClickText}
              </Button>
            ) : (
              ""
            )}

            {props.linkUrl ? (
              <a href={props.linkUrl} target="_blank">
                {props.linkText}
              </a>
            ) : (
              ""
            )}
          </div>
        </CardActions>
      </Box>
    </Card>
  );
};

export default CompanyCard;
