import {
  Box,
  Button,
  Container,
  Fab,
  Fade,
  Grid,
  IconButton,
  Paper,
  Slide,
  TextField,
  Tooltip,
  useScrollTrigger,
} from "@mui/material";

import EditIcon from "@mui/icons-material/Edit";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";

import "./style.scss";
import { Link } from "react-router-dom";
import { DInquiry, DNameValue } from "../../model";
import { API } from "aws-amplify";
import { useState } from "react";
import { flexbox } from "@mui/system";

export interface ITextField {
  label?: string;
  tip?: string;
  editMode?: boolean;
  name?: string;
  value?: string;
  multiline?: boolean;
  onChange?: any;
  onCommit?: any;
}

const BTextField = ({
  label,
  tip,
  editMode,
  name,
  value,
  multiline,
  onChange,
  onCommit,
}: ITextField) => {
  const [myEditMode, setMyEditMode] = useState(editMode);

  return (
    <div style={{ width: "100%" }}>
      <Box sx={{ width: "100%" }}>
        <h3>
          {label}
          {tip && (
            <Tooltip title={tip} arrow>
              <HelpOutlineIcon style={{ fontSize: 20, padding: 2 }} />
            </Tooltip>
          )}
        </h3>
      </Box>

      {myEditMode ? (
        <div
          style={{
            display: "flex",
            width: "100%",
            flexDirection: "row",
            justifyContent: "flex-start",
            alignContent: "flex-start",
            alignItems: "flex-start",
            textAlign: "left",
          }}
        >
          <Box sx={{ flexGrow: 1, justifyContent: "flex-stretch" }}>
            <TextField
              required
              multiline={multiline}
              variant="outlined"
              color="secondary"
              id="name"
              label={label}
              value={value}
              onChange={onChange}
            />
          </Box>
          <Box>
            <IconButton
              color="secondary"
              aria-label="edit"
              component="span"
              onClick={() => {
                if (onCommit) {
                  onCommit();
                }
                setMyEditMode(false);
              }}
            >
              <CheckCircleIcon />
            </IconButton>
          </Box>
        </div>
      ) : (
        <div
          style={{
            display: "flex",
            width: "100%",
            flexDirection: "row",
            justifyContent: "flex-start",
            alignContent: "flex-start",
            alignItems: "flex-start",
            textAlign: "left",
          }}
        >
          <Box
            flexGrow={1}
            sx={{ border: "1px solid silver", p: 1, borderRadius: "15px" }}
          >
            <h3 className="left warning-dark">{value ? value : "(not set)"}</h3>
          </Box>
          <Box>
            <IconButton
              color="secondary"
              aria-label="edit"
              component="span"
              onClick={() => {
                setMyEditMode(true);
              }}
            >
              <EditIcon />
            </IconButton>
          </Box>
        </div>
      )}
    </div>
  );
};

export default BTextField;
