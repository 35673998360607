import React, { useContext, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { UserContext } from "../context/user-context";

import { theme, styles } from "../theme/Theme";
import Banner from "../components/banner/banner";
import { Container, Fade, Grid } from "@mui/material";
import { API } from "aws-amplify";
import { useHistory } from "react-router";
import { DUser, DWebContent } from "../model";
import { ContextService } from "../context/context-service";
import { DPayment } from "../model/my-bsg";
import PaymentCard from "../components/card/payment-card";
import { BSGAPI } from "../auth/bsg-api";

const MyPayment = (props: any) => {
  const { t, i18n } = useTranslation("content");

  const history = useHistory();

  const [webContent, setWebContent] = useState({} as DWebContent);

  const { context, contextUpdate, forceUpdate } = useContext(UserContext);
  const [payments, setPayments] = useState([] as DPayment[]);

  const classes: any = styles(theme);

  useEffect(() => {
    document.title = "BSG Partner:Home";

    context.init().then(() => {
      if (context.user.cognitoUser?.Username) {
        forceUpdate();
        //getMyPayments();
        BSGAPI.getPayments(
          context.user.cognitoCredentials,
          context.user.cognitoUser,
          context.user.person!.id as string
        ).then((data) => {
          setPayments(data as DPayment[]);
        });
      } else {
        context.setCognitoState({
          timestamp: new Date().getTime(),
          redirectLink: {
            external: false,
            uri: `/pay`,
            //external: true,
            //uri: "https://my.bostonsoftwaregroup.com",
          },
        });
        history.push("/sign-in");
      }
    });
    BSGAPI.getWebContent("payment").then((json) => {
      let webContent = json as DWebContent;
      setWebContent(webContent);
      document.title = `${ContextService.SITE_NAME}:${webContent.title}`;
    });
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <Banner>
        <Grid
          container
          direction="column"
          justifyContent="flex-start"
          alignContent="flex-start"
        >
          <Grid
            container
            item
            xs={12}
            justifyContent="flex-start"
            alignItems="flex-start"
          >
            <h1>{webContent.h1?.title}</h1>
          </Grid>
          {webContent.h1?.children?.map((child) => {
            return (
              <Grid
                container
                item
                xs={12}
                justifyContent="flex-start"
                alignItems="flex-start"
              >
                <h2>{child.content}</h2>
              </Grid>
            );
          })}
        </Grid>
      </Banner>

      <div className="section-white">
        <Container maxWidth="lg">
          <Grid
            container
            rowSpacing={8}
            columnSpacing={2}
            direction="row"
            justifyContent="center"
            alignItems="stretch"
            alignContent="center"
          >
            {payments?.map((payment, index) => {
              return (
                <Grid
                  container
                  item
                  xs={12}
                  sm={6}
                  lg={4}
                  justifyContent="center"
                >
                  <PaymentCard payment={payment}></PaymentCard>
                </Grid>
              );
            })}
            {(payments == null || payments.length == 0) && (
              <Grid
                container
                item
                xs={12}
                sm={6}
                lg={4}
                justifyContent="center"
              >
                <PaymentCard
                  payment={null}
                  onClickText="Start"
                  onClick={() => {
                    history.push("/referral");
                  }}
                ></PaymentCard>
              </Grid>
            )}
          </Grid>
        </Container>
      </div>
    </div>
  );
};

export default MyPayment;
