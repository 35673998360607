import {
  Container,
  Fab,
  Fade,
  Grid,
  Paper,
  Slide,
  useScrollTrigger,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
//import { UserContext } from "../../context/user-context";

import LinkedInIcon from "@mui/icons-material/LinkedIn";

import "./style.scss";
import { Link, useHistory } from "react-router-dom";
import QRCode from "../qrcode";

export default function Footer(props: any) {
  const trigger = useScrollTrigger({ target: window ? window : undefined });
  const { t, i18n } = useTranslation("content");

  const history = useHistory();

  let uri = window.location.href;

  useEffect(() => {
    window.addEventListener("hashchange", () => {
      uri = window.location.href;
    });
  }, []);

  return (
    <div className="footer">
      <Container>
        <Grid container spacing={8} justifyContent="flex-start">
          <Grid item xs={12}>
            <div>
              <QRCode text={uri} />
              <h3 className="center">{t("footer.copyright")}</h3>
              <h4 className="center">All Rights Reserved.</h4>
            </div>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
}
