import React, { useContext, useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";

import Card from "@mui/material/Card";
import CardActionArea from "@mui/material/CardActionArea";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Button from "@mui/material/Button";
import "./style.scss";
import ICard from "../../../../ubqsys-com/src/components/card/icard";
import ArrowForwardIosOutlinedIcon from "@mui/icons-material/ArrowForwardIosOutlined";
import {
  Box,
  Chip,
  Container,
  Divider,
  Fab,
  Grid,
  IconButton,
} from "@mui/material";
import { DPayment } from "../../../../ubqsys-com/src/model/my-bsg";
import { DPerson } from "../../../../ubqsys-com/src/model";
import { DLog, DLogItem } from "../../model";
import { BSGAPI } from "../../auth/bsg-api";

import MuiAlert from "@mui/material/Alert";
import AddIcon from "@mui/icons-material/Add";

import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import BusinessIcon from "@mui/icons-material/Business";
import EditLocationAltIcon from "@mui/icons-material/EditLocationAlt";
import BTextField from "../form/text-field";
import BRichTextField from "../form/rich-text-field";
import { UserContext } from "../../context/user-context";
import { isEmptyBindingElement } from "typescript";

const LogItemCard = (props: any) => {
  const { context, contextUpdate, forceUpdate } = useContext(UserContext);

  const history = useHistory();
  const routeTo = (route: string) => {
    history.push(route);
  };

  let [logItem, setLogItem] = useState(props.logItem as DLogItem);

  let [isNew, setIsNew] = useState(false);

  let [edit, setEdit] = useState(false);
  let [addNew, setAddNew] = useState(false);

  const [message, setMessage] = useState("");
  const [showMessage, setShowMessage] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [showErrorMessage, setShowErrorMessage] = useState(false);

  const saveAction = props.onSave as any;
  const deleteAction = props.onDelete as any;

  const resetLogItem = () => {
    let myLogItem = {
      id: -1,
      //updatePerson: context.user.person?.fname,
      note: "",
    };
    setLogItem(myLogItem);
    setAddNew(false);
    setEdit(false);
  };

  const handleAction = () => {
    if (saveAction) {
      saveAction(logItem).then((savedLogItem: DLogItem) => {
        // parent handles newly created object
        // we simply reset:
        //if (isNew) {
        //if (log.id == -1) {
        console.log("savedLogItem: " + JSON.stringify(savedLogItem));
        if (savedLogItem != null && savedLogItem.id != logItem.id) {
          resetLogItem();
        }
        //}
      });
    } else {
      console.log("handle action is missing: onSave");
    }
  };

  const deleteLogItem = () => {
    return new Promise<any>((resolve, reject) => {
      //setLogs([log]);
      console.log("Delete log item ..." + JSON.stringify(logItem));
      //setLogs([...logs, log]);
      /**/
      BSGAPI.deleteCandidateLogItem(
        context.user.cognitoCredentials,
        context.user.cognitoUser,
        logItem
      ).then((response: any) => {
        if (response.status == "ok") {
          //setLogs([response.data, ...logs]);
          //setLogs([...logs, response]);
          //resetLog();
          resolve(response.data as any);
        } else {
          reject(null);
        }
        //resolve(log);
      });
    });
  };

  const handleDeleteAction = () => {
    console.log("Log to be deleted: " + JSON.stringify(logItem));
    deleteLogItem().then((deletedObject: DLogItem) => {
      console.log("after deleted: " + JSON.stringify(deletedObject));

      if (deleteAction) {
        console.log("raedy to call delteAction ...");
        deleteAction(deletedObject);
        setMessage("Note deleted successfully!");
        setShowMessage(true);
      }
    });
  };

  useEffect(() => {
    context.init().then(() => {
      if (context.user.cognitoUser?.Username) {
        if (logItem != null && logItem.id != null && logItem.id != -1) {
          //console.log("person/logItem:" + JSON.stringify(logItem));
        }
      } else {
        context.setCognitoState({
          timestamp: new Date().getTime(),
          redirectLink: {
            external: false,
            uri: `/candidates`,
            //external: true,
            //uri: "https://my.bostonsoftwaregroup.com",
          },
        });
        history.push("/sign-in");
      }
    });
  }, [logItem]);

  return (logItem == null || logItem.id == null || logItem.id == -1) &&
    !addNew ? (
    <h5 className="center">
      <Fab
        variant="extended"
        color="primary"
        size="small"
        onClick={() => {
          setAddNew(true);
          setEdit(true);
        }}
      >
        <AddIcon /> Reply
      </Fab>
    </h5>
  ) : (
    <div className="log-item-card">
      <Box>
        <h2 className="center secondary-light"></h2>
      </Box>

      <Box
        flexGrow={1}
        style={{
          display: "flex",
          width: "100%",
          flexDirection: "column",
          justifyContent: "flex-stretch",
          alignItems: "flex-start",
          padding: 10,
        }}
      >
        {!addNew && (
          <h6 className="left">
            by{" "}
            <Chip
              label={logItem?.updatePersonName}
              size="small"
              icon={<AccountCircleIcon />}
            ></Chip>
            From{" "}
            <Chip
              label={logItem?.updatePersonCompany}
              size="small"
              icon={<BusinessIcon />}
            />{" "}
            on{" "}
            <Chip
              label={BSGAPI.formatDateString(logItem?.createTime?.toString())}
              size="small"
            />
          </h6>
        )}

        {addNew || (logItem.id != null && logItem.id != -1) ? (
          <BRichTextField
            label=""
            editMode={edit}
            name="log-note"
            value={logItem?.note}
            multiline={true}
            onChange={(e: any) => {
              setLogItem({ ...logItem, note: e.target.value });
            }}
            onCommit={() => {
              //alert("test");
              handleAction();
            }}
            onDelete={() => {
              handleDeleteAction();
            }}
            onCancel={() => {
              setAddNew(false);
            }}
          ></BRichTextField>
        ) : (
          ""
        )}
      </Box>

      <Box>
        <Grid
          container
          item
          rowSpacing={0}
          xs={8}
          justifyContent="flex-start"
          alignContent="flex-start"
          alignItems="flex-start"
        >
          {showErrorMessage && (
            <MuiAlert
              elevation={6}
              variant="filled"
              onClose={() => {
                setShowErrorMessage(false);
              }}
              severity="error"
            >
              {errorMessage}
            </MuiAlert>
          )}
        </Grid>
      </Box>

      <Box alignItems="flex-end">
        <CardActions>
          <div className="action-button">
            {props.route ? (
              <Button
                variant="contained"
                size="small"
                color="secondary"
                startIcon={<ArrowForwardIosOutlinedIcon />}
                onClick={() => {
                  routeTo(props.route as string);
                }}
              >
                {props.routeText}
              </Button>
            ) : (
              ""
            )}

            {props.onClick ? (
              <Button
                variant="outlined"
                size="small"
                color="secondary"
                onClick={() => {
                  props.onClick();
                }}
              >
                {props.onClickText}
              </Button>
            ) : (
              ""
            )}

            {props.linkUrl ? (
              <a href={props.linkUrl} target="_blank">
                {props.linkText}
              </a>
            ) : (
              ""
            )}
          </div>
        </CardActions>
      </Box>
    </div>
  );
};
export default LogItemCard;
