import React from "react";
import { Link, useHistory } from "react-router-dom";
import Card from "@mui/material/Card";
import CardActionArea from "@mui/material/CardActionArea";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Button from "@mui/material/Button";
import "./style.scss";
import ICard from "./icard";
import HiringTracker from "../tracker/hiring-tracker";
import { UserContext } from "../../context/user-context";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import LanguageIcon from "@mui/icons-material/Language";
import MoreVertIcon from "@mui/icons-material/MoreVert";

import PersonIcon from "@mui/icons-material/Person";
import PersonAttribute from "./person-attribute";
import ArrowForwardIosOutlinedIcon from "@mui/icons-material/ArrowForwardIosOutlined";
import { Avatar, Box, Chip, Container, Grid, IconButton } from "@mui/material";
import { DPerson } from "../../model/index";

const MemberCard = (props: any) => {
  const history = useHistory();
  const routeTo = (route: string) => {
    history.push(route);
  };

  const member = props.member as DPerson;

  return (
    <Card className="member-card" raised>
      {member != null ? (
        <>
          <Card>
            <Box
              sx={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-start",
                alignContent: "flex-start",
                backgroundColor: "#FBFBFB",
              }}
            >
              <Box
                sx={{
                  justifyContent: "flex-end",
                  alignContent: "flex-end",
                  alignItems: "flex-end",
                }}
              ></Box>
            </Box>

            <Box
              sx={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignContent: "center",
                backgroundColor: "#FBFBFB",
              }}
            >
              <Box
                sx={{
                  justifyContent: "flex-end",
                  alignContent: "flex-end",
                }}
              >
                {member.icon ? (
                  <Avatar
                    alt={`${member.fname} ${member.lname}`}
                    src={member.icon}
                    className="member-avatar"
                  />
                ) : (
                  <Avatar aria-label="person" className="member-avatar">
                    <PersonIcon
                      color="secondary"
                      style={{ fontSize: "40px" }}
                    />
                  </Avatar>
                )}
              </Box>
            </Box>

            <Box
              sx={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignContent: "center",
                backgroundColor: "#FBFBFB",
              }}
            >
              <Box
                flexGrow={1}
                sx={{
                  justifyContent: "center",
                  alignContent: "center",
                }}
              >
                <h2 className="primary-dark center">
                  {member.fname} {member.lname}
                </h2>
              </Box>
            </Box>

            <Box
              sx={{
                width: "100%",
                justifyContent: "center",
                alignContent: "flex-end",
              }}
            >
              <h4 className="center">{member.email}</h4>
              <h4 className="center">{member.phone}</h4>
              {member.wechat && (
                <h4 className="center">WebChat: {member.wechat}</h4>
              )}

              {member.linkedin && (
                <a href={member.linkedin} target="_blank">
                  <LinkedInIcon aria-label="LinkedIn" />{" "}
                </a>
              )}
            </Box>

            <Box flexGrow={1}>
              <h3>{props.children}</h3>
            </Box>

            <Box alignItems="flex-end">
              <Box></Box>
              <CardActions>
                <div className="action-button">
                  {props.route ? (
                    <Button
                      variant="contained"
                      size="small"
                      color="secondary"
                      startIcon={<ArrowForwardIosOutlinedIcon />}
                      onClick={() => {
                        routeTo(props.route as string);
                      }}
                    >
                      {props.routeText}
                    </Button>
                  ) : (
                    ""
                  )}

                  {props.onClick ? (
                    <Button
                      variant="contained"
                      size="small"
                      color="secondary"
                      onClick={() => {
                        props.onClick();
                      }}
                    >
                      {props.onClickText}
                    </Button>
                  ) : (
                    ""
                  )}

                  {props.linkUrl ? (
                    <a href={props.linkUrl} target="_blank">
                      {props.linkText}
                    </a>
                  ) : (
                    ""
                  )}
                </div>
              </CardActions>
            </Box>
          </Card>{" "}
        </>
      ) : (
        <>
          <Box>
            <h1 className="center warning-dark">
              <ArrowForwardIosOutlinedIcon
                color="warning"
                style={{ fontSize: 64 }}
              />
            </h1>
          </Box>
          <Box>
            <h3 className="center">No info to display.</h3>
          </Box>
          <Box>
            {props.onClick ? (
              <Button
                variant="contained"
                size="medium"
                color="warning"
                onClick={() => {
                  props.onClick();
                }}
              >
                {props.onClickText}
              </Button>
            ) : (
              ""
            )}
          </Box>
        </>
      )}
    </Card>
  );
};

export default MemberCard;
