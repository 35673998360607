import React, { useContext, useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";

import Card from "@mui/material/Card";
import CardActionArea from "@mui/material/CardActionArea";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Button from "@mui/material/Button";
import "./style.scss";
import ICard from "../../../../ubqsys-com/src/components/card/icard";
import ArrowForwardIosOutlinedIcon from "@mui/icons-material/ArrowForwardIosOutlined";
import ArrowCircleUpIcon from "@mui/icons-material/ArrowCircleUp";
import ArrowCircleDownIcon from "@mui/icons-material/ArrowCircleDown";
import CircleIcon from "@mui/icons-material/Circle";
import CircleOutlinedIcon from "@mui/icons-material/CircleOutlined";

import {
  Box,
  Chip,
  Container,
  Divider,
  Fab,
  FormControlLabel,
  Grid,
  IconButton,
  MenuItem,
  Popover,
  Radio,
  RadioGroup,
  Select,
  Snackbar,
} from "@mui/material";

import MuiAlert, { AlertProps } from "@mui/material/Alert";
import { DPayment } from "../../../../ubqsys-com/src/model/my-bsg";
import { DPerson } from "../../../../ubqsys-com/src/model";
import { DLog, DLogItem } from "../../model";
import { BSGAPI } from "../../auth/bsg-api";

import AddIcon from "@mui/icons-material/Add";

import EditLocationAltIcon from "@mui/icons-material/EditLocationAlt";
import BTextField from "../form/text-field";
import BRichTextField from "../form/rich-text-field";
import { UserContext } from "../../context/user-context";
import { isEmptyBindingElement } from "typescript";
import LogItemCard from "./log-item-card";
import BSelectList from "../form/select-list";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import BusinessIcon from "@mui/icons-material/Business";
import MilestoneItem from "../tracker/milestone-item";

const LogCard = (props: any) => {
  const { context, contextUpdate, forceUpdate } = useContext(UserContext);

  const history = useHistory();
  const routeTo = (route: string) => {
    history.push(route);
  };

  let [log, setLog] = useState(props.log as DLog);
  let [logItems, setLogItems] = useState([] as DLogItem[]);
  let [logItem, setLogItem] = useState({} as DLogItem);

  const [message, setMessage] = useState("");
  const [showMessage, setShowMessage] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [showErrorMessage, setShowErrorMessage] = useState(false);

  let [isNew, setIsNew] = useState(false);

  let [edit, setEdit] = useState(false);
  let [addNew, setAddNew] = useState(false);

  let [key, setKey] = useState(new Date().toString());

  const saveAction = props.onSave as any;

  const deleteAction = props.onDelete as any;

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const [open, setOpen] = useState(false);
  const id = open ? "simple-popover" : undefined;

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
    setOpen(true);
  };
  const handleClose = () => {
    setAnchorEl(null);
    setOpen(false);
  };

  const resetLogItem = () => {
    setLogItem({
      id: -1,
      note: "",
    });
  };

  const resetLog = () => {
    /** 
    let myLog = {
      id: -1,
      type: BSGAPI.JOB_APPLICATION_TYPE,
      subtype: subtype,
      company: candidate.companyId,
      person: candidate.id as string,
      updatePerson: context.user.person?.fname,
      logDay: new Date(),
      note: "",
    };
    setLog(myLog);
    **/
    setAddNew(false);
    setEdit(false);
  };

  const saveLogItem = (logItem: DLogItem) => {
    return new Promise<DLogItem>((resolve, reject) => {
      if (logItem.id == null || logItem.id == -1) {
        //setLogs(logs.push(log));
        //console.log("update Log Item: " + context.user.person?.id);
        //console.log("candidate company: " + candidate.companyId);

        logItem.person = context.user.person?.id;
        //log.createTi = new Date();
        logItem.log = log.id;

        //setLogs([log]);
        //console.log("Do something ..." + JSON.stringify(logItem));
        //setLogs([...logs, log]);
        /**/
        BSGAPI.addCandidateLogItem(
          context.user.cognitoCredentials,
          context.user.cognitoUser,
          log.person as string,
          logItem
        ).then((response: any) => {
          if (response.status == "ok") {
            setLogItems([...logItems, response.data]);
            //setLogs([...logs, response]);
            resetLogItem();
            resolve(response.data as DLogItem);
          } else {
            reject(null);
          }
          //resolve(log);
        });
      } else {
        //console.log("SaveMe: " + logItem.id);
        //setObjects(...objects, iot);
        //addIoT(iot);
        /**/
        BSGAPI.updateCandidateLogItem(
          context.user.cognitoCredentials,
          context.user.cognitoUser,
          log.person as string,
          logItem
        ).then((response: any) => {
          if (response.status == "ok") {
            resolve(response.data as DLogItem);
          } else {
            reject(null);
          }
        });
      }
    });
  };

  const onSave = (savedLog: DLog, isNew: boolean) => {
    // when saved, notify candidate card so it will refresh.
    const customEvent = new CustomEvent("candidate:log:updated", {
      detail: { log: savedLog, isNew: isNew },
    });
    document.dispatchEvent(customEvent);
  };

  // we save object first, then pass the result to onSave (defined in parent)
  const handleAction = () => {
    //console.log("Log to be saved: " + JSON.stringify(log));

    save().then((savedLog: DLog) => {
      //if (isNew) {

      onSave(savedLog, log.id == -1);
      //
      if (saveAction) {
        saveAction(savedLog, log.id == -1);
      }
      resetLog();

      setKey(new Date().toString());
    });
  };

  const deleteLog = () => {
    return new Promise<any>((resolve, reject) => {
      //setLogs([log]);
      //console.log("Do something ..." + JSON.stringify(log));
      //setLogs([...logs, log]);
      /**/
      BSGAPI.deleteCandidateLog(
        context.user.cognitoCredentials,
        context.user.cognitoUser,
        log
      ).then((response: any) => {
        if (response.status == "ok") {
          //setLogs([response.data, ...logs]);
          //setLogs([...logs, response]);
          //resetLog();
          resolve(response.data as any);
        } else {
          reject(null);
        }
        //resolve(log);
      });
    });
  };

  // we save object first, then pass the result to onSave (defined in parent)
  const handleDeleteAction = () => {
    //console.log("Log to be deleted: " + JSON.stringify(log));
    if (logItems.length > 0) {
      if (deleteAction) {
        //deleteAction(-1, "Replies are not empty");
        setErrorMessage("Please delete replied first. ");
        setShowErrorMessage(true);
      }
    } else {
      deleteLog().then((deletedObject: DLog) => {
        if (deleteAction) {
          deleteAction(deletedObject);
        }
      });
    }
  };
  useEffect(() => {
    //console.log("in log-card: " + JSON.stringify(log));
    setLog(log);
  }, []);

  const save = () => {
    return new Promise<DLog>((resolve, reject) => {
      if (log.id == null || log.id == -1) {
        //setLogs(logs.push(log));
        //console.log("update person: " + context.user.person?.id);
        //console.log("candidate company: " + candidate.companyId);
        //
        //log.milestone =
        //log.company = candidate.companyId;
        //log.person = candidate.id;
        log.updatePerson = context.user.person?.id;
        log.logDay = new Date();

        //setLogs([log]);
        //console.log("Do something ..." + JSON.stringify(log));
        //setLogs([...logs, log]);
        /**/
        BSGAPI.addCandidateLog(
          log.person as string,
          context.user.cognitoCredentials,
          context.user.cognitoUser,
          log
        ).then((response: any) => {
          if (response.status == "ok") {
            //setLogs([response.data, ...logs]);
            //setLogs([...logs, response]);
            //resetLog();
            resolve(response.data as DLog);
          } else {
            reject(null);
          }
          //resolve(log);
        });
      } else {
        //console.log("SaveMe: " + log.id);
        //setObjects(...objects, iot);
        //addIoT(iot);
        /**/
        BSGAPI.updateCandidateLog(
          context.user.cognitoCredentials,
          context.user.cognitoUser,
          log
        ).then((response: any) => {
          if (response.status == "ok") {
            resolve(response.data as DLog);
          } else {
            reject(null);
          }
        });
      }
    });
  };

  const onDeleteLogItem = (deletedObj: DLogItem) => {
    //console.log("Setting updated log items ...." + deletedObj.id);
    if (deletedObj.id != null && deletedObj.id > -1) {
      setLogItems(
        logItems.filter(function (obj) {
          return obj.id !== deletedObj.id;
        })
      );

      setKey(new Date().toString());

      setMessage("Reply deleted successfully!");
      setShowMessage(true);
    } else {
    }
  };

  const getIconColor = ():
    | "inherit"
    | "disabled"
    | "action"
    | "primary"
    | "secondary"
    | "error"
    | "info"
    | "success"
    | "warning"
    | undefined => {
    const found = BSGAPI.JOB_APPLICATION_ASSESSMENT_SUBTYPES.find(
      (type) => type.value == log.subtype
    );
    if (found != null) {
      return found.color;
    } else {
      return "primary";
    }
  };

  const renderIcon = () => {
    switch (log.subtype) {
      case "Strength":
        return <ArrowCircleUpIcon fontSize="large" color={getIconColor()} />;
      case "Mild Strength":
        return <ArrowCircleUpIcon fontSize="small" color={getIconColor()} />;
      case "Mixed":
        return <CircleIcon fontSize="medium" color={getIconColor()} />;
      case "Mild Concern":
        return <ArrowCircleDownIcon fontSize="small" color={getIconColor()} />;
      case "Concern":
        return <ArrowCircleDownIcon fontSize="large" color={getIconColor()} />;
      default:
        return <CircleOutlinedIcon fontSize="medium" color={getIconColor()} />;
    }
  };

  useEffect(() => {
    context.init().then(() => {
      if (context.user.cognitoUser?.Username) {
        if (log != null && log.id != null && log.id != -1) {
          //console.log("person/log:" + JSON.stringify(log));
          BSGAPI.getCandidateLogItems(
            context.user.cognitoCredentials,
            context.user.cognitoUser,
            log.person as string,
            log.id as number
          ).then((response: any) => {
            if (response.status == "ok") {
              setLogItems(response.data as DLogItem[]);
            } else {
            }
          });
        }
      } else {
        context.setCognitoState({
          timestamp: new Date().getTime(),
          redirectLink: {
            external: false,
            uri: `/candidates`,
            //external: true,
            //uri: "https://my.bostonsoftwaregroup.com",
          },
        });
        history.push("/sign-in");
      }
    });
  }, []);

  return (log == null || log.id == null || log.id == -1) && !addNew ? (
    <h5 className="center">
      <Fab
        variant="extended"
        color="secondary"
        size="small"
        onClick={() => {
          setAddNew(true);
          setEdit(true);

          setLog({ ...log, note: "" });
        }}
      >
        <AddIcon /> Add note
      </Fab>
    </h5>
  ) : (
    <Card className="log-card" raised>
      <Box>
        <h2 className="center secondary-light"></h2>
      </Box>
      <Box
        flexGrow={1}
        style={{
          display: "flex",
          width: "100%",
          flexDirection: "column",
          justifyContent: "flex-stretch",
          alignItems: "flex-start",
          padding: 10,
        }}
      >
        {!addNew && (
          <div>
            <h6 className="left">
              by{" "}
              <Chip
                label={log?.updatePersonName}
                size="small"
                icon={<AccountCircleIcon />}
              ></Chip>
              From{" "}
              <Chip
                label={log?.updatePersonCompany}
                size="small"
                icon={<BusinessIcon />}
              />{" "}
              on{" "}
              <Chip
                label={BSGAPI.formatDateString(log?.logDay?.toString())}
                size="small"
              />
            </h6>
          </div>
        )}

        {log.milestone_code && (
          <MilestoneItem
            key={`milestone-${key}-${log.id}`}
            item={log}
            onSave={(savedLog: DLog, isNew: boolean) => {
              onSave(savedLog, isNew);
            }}
            onChange={(e: any) => {
              setLog({ ...log, milestone_value: e.target.value });
            }}
          />
        )}

        {addNew || (log.id != null && log.id != -1) ? (
          <>
            <Fab
              variant="extended"
              color="primary"
              size="small"
              onClick={(e) => {
                handleClick(e);
              }}
            >
              {renderIcon()}

              <span style={{ color: getIconColor() }}>{log.subtype}</span>
            </Fab>
            <Popover
              id={id}
              open={open}
              anchorEl={anchorEl}
              onClose={handleClose}
              anchorOrigin={{
                vertical: "center",
                horizontal: "left",
              }}
              transformOrigin={{
                vertical: "center",
                horizontal: "left",
              }}
            >
              <RadioGroup
                aria-label="gender"
                name="controlled-radio-buttons-group"
                value={log.subtype}
                onChange={(e: any) => {
                  //setItem({ ...item, milestone_value: e.target.value });
                  //handleAction();
                  setLog({ ...log, subtype: e.target.value });
                  handleClose();
                }}
              >
                {BSGAPI.JOB_APPLICATION_ASSESSMENT_SUBTYPES.map((type) => {
                  return (
                    <>
                      <FormControlLabel
                        value={type.value}
                        control={<Radio color="secondary" size="medium" />}
                        label={type.name}
                        checked={log.subtype == type.value}
                      />
                    </>
                  );
                })}
              </RadioGroup>
            </Popover>

            <Container maxWidth="lg">
              <BRichTextField
                label="Note"
                editMode={edit}
                name="log-note"
                value={log?.note}
                multiline={true}
                onChange={(e: any) => {
                  setLog({ ...log, note: e.target.value });
                }}
                onCommit={() => {
                  //alert("test");
                  handleAction();
                }}
                onDelete={() => {
                  handleDeleteAction();
                }}
                onCancel={() => {
                  setAddNew(false);
                }}
              ></BRichTextField>
            </Container>
          </>
        ) : (
          ""
        )}
      </Box>
      <Box>
        <Grid
          container
          item
          rowSpacing={0}
          xs={8}
          justifyContent="flex-start"
          alignContent="flex-start"
          alignItems="flex-start"
        >
          {showMessage && (
            <MuiAlert
              elevation={6}
              variant="filled"
              onClose={() => {
                setShowMessage(false);
              }}
              severity="success"
            >
              {message}
            </MuiAlert>
          )}
          {showErrorMessage && (
            <MuiAlert
              elevation={6}
              variant="filled"
              onClose={() => {
                setShowErrorMessage(false);
              }}
              severity="error"
            >
              {errorMessage}
            </MuiAlert>
          )}
        </Grid>
      </Box>

      {!addNew && (
        <Box sx={{ pl: 4, pr: 1 }}>
          <Grid
            container
            item
            rowSpacing={4}
            xs={12}
            justifyContent="center"
            alignContent="flex-start"
            alignItems="flex-start"
          >
            <Divider />
            {logItems.map((item, index) => {
              return (
                <Grid
                  key={`${key}-grid-log-item-grid-${index}`}
                  container
                  item
                  xs={12}
                  justifyContent="center"
                  alignContent="flex-start"
                  alignItems="flex-start"
                >
                  <LogItemCard
                    key={`${key}-log-item-0`}
                    logItem={item}
                    onSave={saveLogItem}
                    onDelete={onDeleteLogItem}
                  />
                </Grid>
              );
            })}

            <Grid key="log-item-grid-0" container item xs={12}>
              <LogItemCard
                key={`${key}-log-item-0}`}
                logItem={logItem}
                onSave={saveLogItem}
                onDelete={onDeleteLogItem}
              />
            </Grid>
          </Grid>
        </Box>
      )}

      <Box alignItems="flex-end">
        <CardActions>
          <div className="action-button">
            {props.route ? (
              <Button
                variant="contained"
                size="small"
                color="secondary"
                startIcon={<ArrowForwardIosOutlinedIcon />}
                onClick={() => {
                  routeTo(props.route as string);
                }}
              >
                {props.routeText}
              </Button>
            ) : (
              ""
            )}

            {props.onClick ? (
              <Button
                variant="outlined"
                size="small"
                color="secondary"
                onClick={() => {
                  props.onClick();
                }}
              >
                {props.onClickText}
              </Button>
            ) : (
              ""
            )}

            {props.linkUrl ? (
              <a href={props.linkUrl} target="_blank">
                {props.linkText}
              </a>
            ) : (
              ""
            )}
          </div>
        </CardActions>
      </Box>
      <Box>
        <Snackbar
          open={showMessage}
          autoHideDuration={6000}
          onClose={() => {
            setShowMessage(false);
          }}
        >
          <MuiAlert
            onClose={() => {
              setShowMessage(false);
            }}
            severity="success"
            sx={{ width: "100%" }}
          >
            {message}
          </MuiAlert>
        </Snackbar>
      </Box>
    </Card>
  );
};

export default LogCard;
