import React, { useContext, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { UserContext } from "../context/user-context";

import { theme, styles } from "../theme/Theme";
import Banner from "../components/banner/banner";
import { Chip, Container, Fab, Fade, Grid } from "@mui/material";
import { API } from "aws-amplify";
import { useHistory } from "react-router";
import {
  DCompany,
  DPerson,
  DUser,
  DUserAttribute,
  DWebContent,
} from "../model";
import { ContextService } from "../context/context-service";

import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import BusinessIcon from "@mui/icons-material/Business";
import BubbleChartOutlinedIcon from "@mui/icons-material/BubbleChartOutlined";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import AssignmentIndIcon from "@mui/icons-material/AssignmentInd";

import IconCard from "../components/card/icon-card";
import { BSGAPI } from "../auth/bsg-api";
import CompanyCard from "../components/card/company-card";

const MyHome = (props: any) => {
  const { t, i18n } = useTranslation("content");

  const history = useHistory();

  let { context, contextUpdate, forceUpdate } = useContext(UserContext);

  const [webContent, setWebContent] = useState({} as DWebContent);

  const [paymentTotal, setPaymentTotal] = useState(0.0);
  const [opportunityTotal, setOpportunityTotal] = useState(0);
  const [userAttribute, setUserAttribute] = useState({} as DUserAttribute);
  const [companies, setCompanies] = useState([] as DCompany[]);
  const [clients, setClients] = useState([] as DCompany[]);

  useEffect(() => {
    document.title = "BSG Partner:Home";

    context.init().then(() => {
      //if (context.user.cognitoUser?.Username && !context.user.jwtExpired) {
      if (context.user.cognitoUser?.Username) {
        //console.log("I am here ..." + context.user.cognitoUser?.username);

        forceUpdate();

        // get company (as Client manager)
        BSGAPI.getCompanies(
          context.user.cognitoCredentials,
          context.user.cognitoUser
        ).then((response: any) => {
          //setOpportunityTotal(parseInt(response.data.total));
          //console.log("response: " + JSON.stringify(response));

          if (response.status == "ok") {
            setCompanies(response.data as DCompany[]);
          } else if (response.status == "token-expired") {
            history.push("/sign-in?flow=REFRESH_TOKEN_AUTH");
          } else {
          }
        });

        // get clients as BSG manager
        BSGAPI.getClients(
          context.user.cognitoCredentials,
          context.user.cognitoUser
        ).then((response: any) => {
          if (response.status == "ok") {
            setClients(response.data as DCompany[]);
          } else if (response.status == "token-expired") {
            history.push("/sign-in?flow=REFRESH_TOKEN_AUTH");
          } else {
          }
        });

        // load opportunity total
        if (context.user.profile?.nav?.item_referral) {
          BSGAPI.getOpportunitySummary(
            context.user.cognitoCredentials,
            context.user.cognitoUser
          ).then((response: any) => {
            if (response.status == "ok") {
              setOpportunityTotal(parseInt(response.data.total));
            } else if (response.status == "token-expired") {
              history.push("/sign-in?flow=REFRESH_TOKEN_AUTH");
            } else {
            }
          });
        }

        // load payment summary

        if (context.user.profile?.nav?.item_payment) {
          BSGAPI.getPaymentSummary(
            context.user.cognitoCredentials,
            context.user.cognitoUser,
            context.user.person!.id as string
          ).then((response: any) => {
            if (response.status == "ok") {
              setPaymentTotal(parseFloat(response.data.total));
            } else if (response.status == "token-expired") {
              history.push("/sign-in?flow=REFRESH_TOKEN_AUTH");
            } else {
            }
          });
        }
        // load profile - user attributes summary

        if (context.user.profile?.nav?.item_my_profile) {
          BSGAPI.getUserAttributes(
            context.user.cognitoCredentials,
            context.user.cognitoUser
          ).then((response: any) => {
            //console.log("User Attribute: " + JSON.stringify(response));
            if (response.status == "ok") {
              setUserAttribute(response.data as DUserAttribute);
            } else if (response.status == "token-expired") {
              history.push("/sign-in?flow=REFRESH_TOKEN_AUTH");
            } else {
            }
          });
        }
      } else {
        context.setCognitoState({
          timestamp: new Date().getTime(),
          redirectLink: {
            external: false,
            uri: `/home`,
            //external: true,
            //uri: "https://my.bostonsoftwaregroup.com",
          },
        });
        history.push("/sign-in");
      }
    });

    BSGAPI.getWebContent("home").then((json) => {
      let webContent = json as DWebContent;
      setWebContent(webContent);
      document.title = `${ContextService.SITE_NAME}:${webContent.title}`;
    });
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <Banner>
        <Grid
          container
          direction="column"
          justifyContent="flex-start"
          alignContent="flex-start"
        >
          <Grid
            container
            item
            xs={12}
            justifyContent="flex-start"
            alignItems="flex-start"
          >
            <h1>{webContent.h1?.title}</h1>
          </Grid>
          {webContent.h1?.children?.map((child) => {
            return (
              <Grid
                container
                item
                xs={12}
                justifyContent="flex-start"
                alignItems="flex-start"
              >
                <h2>{child.content}</h2>
              </Grid>
            );
          })}
        </Grid>
      </Banner>

      <div className="section-white">
        <Container maxWidth="lg">
          <Grid
            container
            rowSpacing={8}
            columnSpacing={2}
            justifyContent="center"
            alignItems="stretch"
            alignContent="center"
          >
            {context.user.profile?.nav?.item_referral && (
              <Grid
                container
                item
                xs={12}
                sm={12}
                md={6}
                lg={4}
                justifyContent="center"
                alignContent="center"
              >
                <IconCard
                  icon={<PeopleAltIcon color="info" style={{ fontSize: 64 }} />}
                  title="Referrals"
                  description="You've referred people"
                  onClick={() => {
                    history.push("/referral");
                  }}
                  onClickText="Review"
                >
                  <Chip
                    label={`${opportunityTotal}`}
                    variant="outlined"
                    color="warning"
                    size="medium"
                    sx={{ fontSize: "16pt", p: 3 }}
                    onClick={() => {
                      history.push("/referral");
                    }}
                  ></Chip>
                </IconCard>
              </Grid>
            )}
            {context.user.profile?.nav?.item_payment && (
              <Grid
                container
                item
                xs={12}
                sm={12}
                md={6}
                lg={4}
                justifyContent="center"
                alignContent="center"
              >
                <IconCard
                  icon={
                    <AttachMoneyIcon color="info" style={{ fontSize: 64 }} />
                  }
                  title="My Pay "
                  description="You've made amount"
                  onClick={() => {
                    history.push("/pay");
                  }}
                  onClickText="Review"
                >
                  <Chip
                    label={`$${paymentTotal}`}
                    variant="outlined"
                    color="warning"
                    size="medium"
                    sx={{ fontSize: "16pt", p: 3 }}
                    onClick={() => {
                      history.push("/pay");
                    }}
                  ></Chip>
                </IconCard>
              </Grid>
            )}

            {context.user.profile?.nav?.item_my_profile && (
              <Grid
                container
                item
                xs={12}
                sm={12}
                md={6}
                lg={4}
                justifyContent="center"
                alignContent="center"
              >
                <IconCard
                  icon={
                    <AssignmentIndIcon color="info" style={{ fontSize: 64 }} />
                  }
                  title="My Profile"
                  description="Last time updated profile"
                  onClick={() => {
                    history.push("/profile");
                  }}
                  onClickText="Review"
                >
                  <Chip
                    label={BSGAPI.formatDateString(userAttribute!.updateTime)}
                    variant="outlined"
                    color="warning"
                    size="medium"
                    sx={{ fontSize: "16pt", p: 3 }}
                    onClick={() => {
                      history.push("/profile");
                    }}
                  ></Chip>
                </IconCard>
              </Grid>
            )}

            {companies?.map((company) => {
              return (
                <Grid
                  container
                  item
                  xs={12}
                  sm={12}
                  md={6}
                  lg={4}
                  justifyContent="center"
                  alignContent="center"
                >
                  <CompanyCard
                    company={company}
                    route={`/candidates/${company.id}`}
                    routeText="Job Candidates"
                  ></CompanyCard>
                </Grid>
              );
            })}

            {clients?.map((client) => {
              return (
                <Grid
                  container
                  item
                  xs={12}
                  sm={12}
                  md={6}
                  lg={4}
                  justifyContent="center"
                  alignContent="center"
                >
                  <CompanyCard
                    company={client}
                    route={`/candidates/${client.id}`}
                    routeText="Job Candidates"
                  ></CompanyCard>
                </Grid>
              );
            })}
          </Grid>
        </Container>
      </div>
    </div>
  );
};

export default MyHome;
