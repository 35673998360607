import React, { useContext, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { UserContext, UserContextProvider } from "../context/user-context";

import useScrollTrigger from "../hooks/scroll-trigger";
import { Button, Container, Fade, Grid, TextField } from "@mui/material";
import ThemeCard from "../components/card/theme-card";
import "../custom.d.ts";
import "./style.scss";
import Banner from "../components/banner/banner";
import LinkedInIcon from "@mui/icons-material/LinkedIn";

import Card from "@mui/material/Card";
import { API } from "aws-amplify";
import {
  DFormValidation,
  DJob,
  DLinkedInCompositeProfile,
  DLinkedInEmailHandler,
  DLinkedInProfile,
} from "../model";
import { useHistory } from "react-router-dom";
import { Password } from "@mui/icons-material";
import { BSGAPI } from "../auth/bsg-api";

import VpnKeyIcon from "@mui/icons-material/VpnKey";
import { ContextService } from "../context/context-service";
const ChangePassword = (props: any) => {
  //const { children, window } = props;
  const { t, i18n } = useTranslation("content");

  const { context, contextUpdate, forceUpdate } = useContext(UserContext);
  const history = useHistory();

  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [passwordAgain, setPasswordAgain] = useState("");
  const [newPasswordTouched, setNewPasswordTouched] = useState(false); // track if the form is touched by user.
  const [newPasswordValidation, setNewPasswordValidation] = useState({
    isValid: true,
    err: "",
  } as DFormValidation);
  const [passwordAgainValidation, setPasswordAgainValidation] = useState({
    isValid: true,
    err: "",
  } as DFormValidation);

  const [success, setSuccess] = useState(false);

  const myRefWe = useRef<HTMLDivElement>(null);

  const getTrigger = useScrollTrigger();

  const [triggerWe, setTriggerWe] = useState(
    getTrigger(myRefWe.current).trigger
  );

  const changePassword = () => {
    //console.log("old/new: " + oldPassword + ":" + newPassword);
    context
      .cognitoChangePassword(oldPassword, newPassword)
      //.cognitoChangePassword("XYZ123abc", "123abcXYZ")
      .then(() => {
        //console.log("data: " + data);
        setSuccess(true);
      })
      .catch(() => {
        // TODO:  failure reason
      });
  };

  useEffect(() => {
    window.onscroll = () => {
      setTriggerWe(getTrigger(myRefWe.current).trigger);
    };

    document.title = `${ContextService.SITE_NAME}:Change Password`;
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <Fade in={triggerWe} timeout={4000}>
        <div ref={myRefWe}>
          <Banner>
            <Grid
              container
              spacing={3}
              direction="column"
              justifyContent="flex-start"
              alignContent="flex-start"
            >
              <Grid
                container
                item
                xs={12}
                justifyContent="flex-start"
                alignItems="flex-start"
              >
                <h1>Password Reset</h1>
              </Grid>
              <Grid
                container
                item
                xs={12}
                justifyContent="flex-start"
                alignItems="flex-start"
              >
                <h2>
                  If you use Google account to login, you don't need to reset
                  your password here.{" "}
                </h2>
              </Grid>
            </Grid>
          </Banner>
        </div>
      </Fade>

      <div>
        <Container maxWidth="md">
          <h1> Change Password </h1>

          {success ? (
            <h2>Your password has been changed. </h2>
          ) : (
            <div>
              <Grid
                container
                spacing={3}
                direction="row"
                justifyContent="flex-start"
                alignContent="flex-start"
              >
                <Grid
                  container
                  item
                  xs={12}
                  justifyContent="flex-start"
                  alignContent="flex-start"
                >
                  <VpnKeyIcon color="secondary" />{" "}
                  <TextField
                    required
                    id="outlined-required"
                    variant="outlined"
                    color="secondary"
                    label="Current Password"
                    type="password"
                    defaultValue={oldPassword}
                    onChange={(e) => {
                      setOldPassword(e.target.value);
                    }}
                  />
                </Grid>
                <Grid
                  container
                  item
                  xs={12}
                  justifyContent="flex-start"
                  alignContent="flex-start"
                >
                  <VpnKeyIcon color="warning" />{" "}
                  <TextField
                    required
                    id="outlined-password-input"
                    variant="outlined"
                    color="warning"
                    label="New Password"
                    type="password"
                    error={!newPasswordValidation.isValid}
                    helperText={newPasswordValidation.err}
                    defaultValue={newPassword}
                    onChange={(e) => {
                      //setTouched(true);
                      setNewPasswordTouched(true);
                      setNewPassword(e.target.value);
                      setNewPasswordValidation(
                        BSGAPI.validate(
                          BSGAPI.RegExp_PASSWORD,
                          "password",
                          e.target.value,
                          "Password minimum length: 6"
                        )
                      );
                      setPasswordAgainValidation({
                        isValid: passwordAgain == e.target.value,
                        err:
                          passwordAgain == e.target.value
                            ? ""
                            : "Password does not match",
                      });
                    }}
                  />
                </Grid>
                <Grid
                  container
                  item
                  xs={12}
                  justifyContent="flex-start"
                  alignItems="flex-start"
                >
                  <VpnKeyIcon color="warning" />{" "}
                  <TextField
                    required
                    type="password"
                    id="outlined-required"
                    variant="outlined"
                    color="warning"
                    label="Password (again)"
                    error={!passwordAgainValidation.isValid}
                    helperText={passwordAgainValidation.err}
                    defaultValue={newPassword}
                    onChange={(e) => {
                      //setTouched(true);
                      //setPasswordTouched(true);
                      //setPassword(e.target.value);

                      setPasswordAgain(e.target.value);
                      setPasswordAgainValidation({
                        isValid: newPassword == e.target.value,
                        err:
                          newPassword == e.target.value
                            ? ""
                            : "Password does not match",
                      });
                    }}
                  />
                </Grid>

                <Grid
                  container
                  item
                  xs={12}
                  justifyContent="flex-start"
                  alignItems="flex-start"
                >
                  <h1></h1>
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={() => changePassword()}
                    disabled={
                      !newPasswordTouched ||
                      !newPasswordValidation.isValid ||
                      !passwordAgainValidation.isValid
                    }
                  >
                    Change Password
                  </Button>
                </Grid>
              </Grid>
            </div>
          )}
        </Container>
      </div>
    </div>
  );
};

export default ChangePassword;
function setNewPasswordValidation(arg0: { isValid: boolean; err: string }) {
  throw new Error("Function not implemented.");
}
